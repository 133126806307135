<template>
  <div>
    <!-- <h1>This is an Business Card page</h1> -->

    <!-- <div>bccode: {{ $route.params.bccode }}</div>
    {{ '../assets/' + $route.params.bccode + '/pic_businesscard.png' }} -->

<!-- <div>bccode: {{ $route.params.bccode }}</div> -->
<!-- <div>Param: {{bccode}}</div> -->

    <div v-if="!bcpath">
      <h1>
        Business Card not found
      </h1>

      <h3>
        INVALID PATH
      </h3>

      <b>>> Access code required in URL </b>
      <br>
      <b>>> scan QR code on business card</b>
    </div>

    <div v-if="bcpath">

      <h2>Business Card</h2>

      <div style="display: inline-block;">
        <img style="width: 90%; height: auto;  object-fit: contain;" :src="bcpath" alt="businesscard"/>
        <!-- <img style="display:block; margin:auto;" :src="bcpath" alt="businesscard"/> -->
    </div>
      <h2>VCARD</h2>

      >> Scan to save contact data 

      <!-- <div style="width:100%;margin:0 auto;">
        <a target="_blank" :href="vcfpath"><span style="font-size: 22px; font-family='Helvetica';">Download my VCARD</span></a>
      </div> -->

      <img style="style=width: 90%; height: auto; display:block;margin:auto;" :src="qrpath" alt="QR" vspace="50em;"/>


    </div>

    <div style="background: white; float: center;">
      (c) 2021 <a href="https://jueewo.com">jueewo ventures</a> |
      <a  href="https://jueewo.com/impressum/">Impressum</a> | 
      <a  href="https://jueewo.com/privacy/">Privacy</a>
      <!--
      <a href="https://labs.jueewo.com/page/legal/privacy/">Privacy</a>
      |
      -->
    </div>
  
  </div>
</template>

<script>
// import { set } from 'vue/types/umd'
// import { toRefs } from 'vue'

export default {
  components: {  },
  props: {
    bccode: {
      type: String,
      required: true
    }
  },
  // created: {
    
    // bcpath: require('../assets/' + this.bccode + '/pic_businesscard.png'),
    // vcfpath: '../assets/' + this.bccode + '/vcard.vcf',
    // qrpath: require('../assets/' + this.bccode + '/pic_qrcode.png')

    // setup()
  // },
  // mounted: function () {
  //   this.setup()
  // },
  data: function () {
    return {
      bcpath: require('../assets/' + this.bccode + '/pic_businesscard.png'),
      vcfpath: '../assets/' + this.bccode + '/vcard.vcf',
      qrpath: require('../assets/' + this.bccode + '/pic_qrcode.png'),
    }
  },
  methods: {
    // setup() {

    //   console.log("> log: ", this.bccode)

    //   // bcpath = require('../assets/' + this.bccode + '/pic_businesscard.png');
    //   // vcfpath =  '../assets/' + this.bccode + '/vcard.vcf';
    //   // qrpath = require('../assets/' + this.bccode + '/pic_qrcode.png');
    // }
  }



//   setup(props) {

//     const { bccode } = toRefs(props)

//     // console.log(props) // { user: '' }
//     // console.log(bccode.value)
// try {
//   var bcpath = require('../assets/' + bccode.value + '/pic_businesscard.png');
//   var vcfpath = '../assets/' + bccode.value + '/vcard.vcf'
//   var qrpath = require('../assets/' + bccode.value + '/pic_qrcode.png');

// } catch (error) {
//   console.log("NO PATH FOUND")
// }
    


//     return {
//       bcpath,
//       vcfpath,
//       qrpath,
//     } // anything returned here will be available for the rest of the component
//   }
  // the "rest" of the component
}

</script>
